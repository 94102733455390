@import 'https://fonts.googleapis.com/css?family=Montserrat:400,700';
@import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700';
@import "../template/mixin_templates";

/* Variables */
$largeur_fenetre_grand: $xlarge-screen - 80;

/*$small-screen: 640px;
$medium-screen: 1024px;
$large-screen: 1200px;
$xlarge-screen: 1440px;*/

/*****************************************************/
/********************* FUNCTIONS *********************/
/*****************************************************/

/*****************************************************/
/********************* KEYFRAMES *********************/
/*****************************************************/

@keyframes productBounding {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes menu-fixed {
  0% {
    top: -61px;
  }
  100% {
    top: 0;
  }
}

/***************************************************/
/********************* GENERAL *********************/
/***************************************************/

body, html {
  width: 100%;
  font-size: 16px;
  font-family: 'Source Sans Pro', 'Arial', sans-serif;
  margin: 0;
  box-sizing: border-box;
}


#main-conteneur {
 overflow: hidden;
}

.form-group {
  label {
    margin-bottom: 10px;
  }

  input[type=text],input[type=password] {
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;

    &:focus {
      outline: none;
      border-color: rgba(0, 0, 0, 0.5);
    }
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

@include titreBaseSize();

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Montserrat', 'Arial', sans-serif;
  opacity: 1;
  visibility: visible;
}

h1, h2, h3,
.h1, .h2, .h3 {
  font-weight: 700;
  margin-top: .8em;
  margin-bottom: .5em;
}

.wrapper-content {
  h1, h2, h3,
  .h1, .h2, .h3 {
    display: inline-block;
    padding: .35em .55em;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

h1, .h1 {
  font-size: 40px;
}

h2, .h2 {
  font-size: 28px;
}

h3, .h3 {
  font-size: 22px;
}

h4, .h4 {
  font-size: 16px;
}

h5, .h5 {
  font-size: 14px;
}

h6, .h6 {
  font-size: 12px;
}

a {
  text-decoration: none;
  img {
    border: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  padding: 0;
  list-style: none;
  list-style-position: inside;
}

iframe {
  max-width: 100%;
}

table {
  position: relative;
  table-layout: fixed;
  max-width: 100% !important;
}

.formulaires_perso .listeQuestions {
  overflow: hidden;
}

.bouton, .button:not(.button_galerie):not(.btn-search),
input[type="submit"], .form-newsletter input[type="submit"],
body .template_fiche_produit_3 #fiche-produit .wrap-description .side-tabs a {
  margin: 10px;
  padding: 10px;
  border-width: 2px;
  border-style: solid;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  //text-transform: uppercase;
  position: relative;
  overflow: hidden;
  transition: all .2s linear;
  font-size: 16px !important;
  top: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  i {
    margin-right: 10px;
  }
  &:hover {
    cursor: pointer;
  }
  &:hover, &:focus {
    top: -5px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  }
}

/* Conteneur principal */
.wrapper, #headercontent {
  width: $largeur_fenetre_grand;
  margin: 0 auto;
  position: relative;
  padding: 0 20px 20px;
  //z-index:1;
  &::after, &::before {
    content: '';
    display: block;
    clear: both;
  }
}

#pub {
  width: $largeur_fenetre_grand;
  margin: 0 auto;
}

.wrapper {
  padding-top: 20px;
}

.content_p {
  position: relative;
  z-index: 0;
}

/* site en deux colonnes */
.sidebar, section.col-md-10 {
  float: left;
  padding: 20px;
}

.sidebar {
  width: 220px/$largeur_fenetre_grand * 100%;
  position: relative;
  margin-top: 175px;
  padding: 0;
  nav > ul {
    margin: 0;
  }
  nav > ul > li {
    display: block;
    > a {
      font-size: 22px;
      font-weight: 700;
      margin-top: .8em;
      margin-bottom: 30px;
      font-family: 'Montserrat', 'Arial', sans-serif;
    }
    &:first-child > a {
      margin-top: 0;
    }
    a {
      display: block;
    }
    ul, li {
      display: block;
    }
    > ul > li {
      border-bottom: 1px solid rgba(0, 0, 0, .1);
    }
    > ul > li {
      a {
        padding: 5px 0;
      }
      li a {
        padding-left: 10px;
      }
    }
  }
}

section.col-md-10 {
  padding-top: 0;
  width: 100%-(220px/$largeur_fenetre_grand * 100%);
}

.wrapper-content {
  clear: both;
  position: relative;
  min-height: 500px;
}

/* fil d'Arianne */
.nav-static-breadcrumb {
  font-size: 0;
  li {
    display: inline-block;
    font-size: 1rem;
    &:not(:first-child)::before {
      content: '> ';
      display: inline-block;
      margin: 0 10px;
    }
  }
}

/**************************************************/
/********************* HEADER *********************/
/**************************************************/

header {
  transition: top .2s linear;
  top: 0;
  position: relative;
  &::after, &::before {
    content: '';
    display: block;
    clear: both;
  }
}

.header-conteneur {
  width: $largeur_fenetre_grand;
  margin: 0 auto;
  position: relative;
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.header-conteneur #blocContent {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

#headercontent {
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  top: 0;
  left: 0;
  //overflow: hidden;
  position: absolute;
  width: 100%;
  font-size: 0;
  //z-index:2;
  .container-slogan, #logo {
    display: inline-block;
    z-index: auto;
    font-size: 0;
    vertical-align: middle;
    h1, .h1 {
      display: inline-block;
      /* IE */
      line-height: inherit;
      /* Other */
      line-height: initial;
      margin: 0;
      vertical-align: middle;
      padding: 0;
      box-shadow: none;
    }
  }
  #logo {
    height: 100%;
    max-height: 100%;
    img {
      vertical-align: top;
      display: inline-block;
      max-width: 100%;
      max-height: 100%;
    }
    &.logo_cmonsite_default {
      img {
        width: auto;
      }
    }
  }
  .container-slogan {
    left: 0;
  }
}

.logo {
  //position:relative;
  display: inline-block;
  img {
    max-height: 100px;
    max-width: none;
    margin-right: 20px;
  }
}

.slogan_p {
  font-weight: normal;
  font-family: 'Source Sans Pro', 'Arial', sans-serif;
  font-size: 14px;
}

.slogan {
  opacity: 1;
  visibility: visible;
  transition: opacity .3s linear, visibility 0s linear .3s;
}

.is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  #headercontent {
    .img-logo {
      top: 5px;
      //height: 50px !important;
      width: auto !important;
    }
    .container-slogan {
      height: 100%;
    }
    #logo {
      max-height: 100%;
      top: 0 !important;
    }
    .slogan {
      opacity: 0;
      visibility: hidden;
      transition-delay: 0s;
    }
  }
}

#bloc-fil-title {
  padding: 10px 0 50px;
  margin: -20px 0 20px 0;

  background-attachment: scroll;
  text-align: center;
  box-sizing: border-box;
  position: relative;
  flex: none;
  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100vw;
    top: 0;
    left: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, .5) inset;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .arianne {
    margin: 25px auto -20px;
    padding: 0 20px;
    font-size: 0;
    left: 0;
    right: 0;
    z-index: auto;
    text-align: center;
    max-width: $largeur_fenetre_grand;
    box-sizing: border-box;
    position: relative;
    display: block;
    clear: both;

    /*~ h2{
            margin-top:0;
        }*/

    li {
      display: inline-block;
      font-size: 13px;
      float: none;
      &:not(:first-child)::before {
        content: '\f0a9';
        display: inline-block;
        margin: 0 10px;
        font-family: fontAwesome, sans-serif;
      }
    }
  }
  h2, .h2,
  h1, .h1 {
    width: 100%;
    border-width: 0;
    font-size: 30px;
    font-weight: bold;
    padding: 0 20px;
    margin: 25px auto -20px;
    /* IE */
    line-height: inherit;
    /* Other */
    line-height: initial;
    position: relative;
    z-index: auto;
    background: transparent !important;
    max-width: $largeur_fenetre_grand;
    box-sizing: border-box;
    display: block !important;
    box-shadow: none;

    &::first-letter {
      padding: 0 !important;
      margin: 0 !important;
      background: transparent !important;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
}

/************************************************/
/********************* MENU *********************/
/************************************************/

header .menu {
  width: 80%;
  float: right;
  position: relative;
}

.cmonsite-panier-2 #nav-principal > ul {
  max-width: none;
}

nav#nav-principal {
  position: relative;
  width: 100%;
  padding: 0;
  margin: auto;
  height: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;

  &::after {
    content: '';
    clear: both;
    display: block;
  }
  > ul {
    flex: 0 1 auto;
    font-size: 0;
    float: right;
    height: 100%;
    display: block;
    text-align: right;
    li ul {
      position: absolute;
      top: 100%;
      left: -9999px;
      margin-top: -15px;
      min-width: 100%;
      opacity: 0;
      padding: 0;
      border-width: 2px;
      border-style: solid;
      box-shadow: 0 0 10px 0 rgba(50, 50, 50, 0.1);
      transition: opacity .2s linear, margin-top .2s linear;
      z-index: 100;
      text-align: left;
      &::before {
        display: block;
        content: "";
        position: absolute;
        top: -10px;
        left: 25px;
        width: 0;
        height: 0;
        border-width: 0 6px 8px 6px;
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
        border-style: solid;
        z-index: 101;
      }
      &::after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: -15px;
        width: 15px;
        height: 100%;
        z-index: 101;
      }
      li {
        display: block;
        padding: 0;
        &:hover > ul {
          left: 100%;
          top: -2px;
          margin-left: 10px;
          z-index: 102;
          &::before {
            top: 15px;
            left: -8px;
            border-width: 6px 6px 8px 0;
            border-left-color: transparent;
            border-bottom-color: transparent;
            border-top-color: transparent;
          }
          &.menu-protection-droite {
            left: auto;
            right: 100%;
            margin-left: 0;
            margin-right: 10px;
            &::before {
              left: auto;
              right: -8px;
              border-width: 6px 0 8px 6px;
              border-right-color: transparent;
              border-bottom-color: transparent;
              border-top-color: transparent;
            }
            &::after {
              left: auto;
              right: -15px;
              width: 15px;
              height: 100%;
              z-index: 101;
            }
          }
        }
      }
      ul {
        left: 100%;
        top: -9999px;
      }
    }
    > li {
      //height: 100%;
      display: inline-block;
      float: none;
      padding: 0 5px 0 0;
      &:not(:first-child) {
        padding-left: 5px;
      }
      &.hasDropdown > a span::after {
        font-family: 'fontAwesome', sans-serif;
        content: "\f107";
        display: inline-block;
        margin-left: 5px;
      }
      > a {
        width: 100%;
        //height: 100%;
        display: block;
        line-height: 50px;
        font-weight: 400;
        padding: 0 5px;
        font-family: 'Montserrat', 'Arial', sans-serif;
        span {
          /* IE */
          line-height: inherit;
          /* Other */
          line-height: initial;
          display: inline-block;
          vertical-align: middle;
        }
      }
      > ul {
        min-width: calc(100% + 30px);
      }
    }
    @for $i from 1 through 8 {
      > li:first-child:nth-last-child(#{$i}),
      > li:first-child:nth-last-child(#{$i}) ~ li {
        > a {
          line-height: 100px;
        }
      }
    }
  }
  li {
    display: inline-block;
    font-size: 1rem;
    position: relative;
    &:hover {
      > ul {
        left: -15px;
        margin-top: 0;
        opacity: 1;
      }
    }
  }
  a {
    padding: 10px 25px 10px 15px;
    width: 100%;
    display: block;
    white-space: nowrap;
    font-family: 'Source Sans Pro', 'Arial', sans-serif;
  }
}

.is-fixed {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  animation-name: menu-fixed;
  animation-duration: .5s;
  nav#nav-principal {
    min-height: 50px;
    > ul > li > a {
      line-height: 50px !important;
    }

    .template-panier.template-panier-2 {
      height: 50px;

      #panier #header-panier {
        line-height: 30px;

        .count-live {
          top: 15px;
        }
      }
    }
  }
}

/****************************************************/
/********************* PRODUITS *********************/
/****************************************************/

/*Général*/
.produits {
  float: left;
  width: 24%;
  margin: .5%;
  position: relative;
  z-index: 1;
  .remise {
    position: absolute;
    z-index: 100;
    margin-top: 0;
    padding: 10px;
    top: 64px;
    font-family: 'Montserrat', 'Arial', sans-serif;
    font-weight: 700;
  }
  .productImageWrap {
    position: relative;
    overflow: hidden;
    a img {
      width: 100%;
    }
  }
  .mask {
    position: absolute;
    width: 100%;
    a {
      opacity: 0;
    }
  }
  .oldprix {
    text-decoration: line-through;
  }
  &:hover {
    .mask a {
      opacity: .5;
    }
  }
}

.nomprod a {
  font-size: inherit;
}

.template_boutique_all {
  .produits {
    .addbasket, .hors-stock {
      outline: 0;
      position: absolute;
      top: 0 !important;
      padding: 0;
      padding-top: 100%;
      margin: 0;
      width: 50%;
      left: 0;
      height: auto !important;
      overflow: hidden;
      /* Hack pour eviter la personnalisation de la class button de base sur le fond*/
      background: transparent !important;
      border: 0;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
      &:hover {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
    }
    .inside-addbasket, .inside-hors-stock {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      //height: 50px;
      min-height: 52px; //Rajouté
      transform: translateY(100%);
      line-height: 45px;
      z-index: 20;
      text-align: center;
      border-width: 1px;
      border-style: solid;
      text-transform: none;
      transition: all .2s linear;
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      justify-content: center;
      span, .sub-hors-stock {

        line-height: 17px;
        display: inline-block;
        font-size: 16px;
        vertical-align: middle;
        padding: 5px;
        i {
          display: inline-block;
          width: 100%;
        }
      }
    }
    .productImageWrap::before {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background: rgba(0, 0, 0, 1);
      transition: all .2s linear;
      opacity: 0;
    }
    .mask {
      z-index: auto;
    }
    .productImageWrap .mask .zoom, .productImageWrap .zoom {
      position: absolute;
      padding: 0;
      padding-top: 100%;
      width: 100%;
      height: 50px;
      right: 0;
      left: auto;
      top: 0;
      overflow: hidden;
      bottom: 0;
      opacity: 1;
      background: transparent !important;
      border: 0;
      margin: 0;
      &::after {
        display: none;
      }
      &::before {
        display: none;
      }
      .zoom-inner {
        &::before {
          content: "\f06e";
          display: inline-block;
          font-family: fontAwesome, sans-serif;
        }
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        line-height: 50px;
        //height: 50px;
        min-height: 50px; //Rajouté
        transform: translateY(100%);
        width: 50%;
        text-align: center;
        top: auto;
        bottom: 0;
        right: 0;
        left: auto;
        border-width: 1px;
        border-style: solid;
        box-sizing: border-box;
        margin: 0;
        position: absolute;
      }
    }
    .prix {
      text-align: left;
      margin: 16px 0;
      padding: 0 20px;
      .oldprix {
        font-size: 70%;
        line-height: 80%;
        position: relative;
        top: -5px;
        opacity: .8;
        float: none;
        display: inline;
        margin: 0 10px 0 0;
      }
      .prixprod {
        float: none;
        display: inline;
        margin: 0 10px 0 0;
      }
    }
    .nomprod {
      text-align: left;
      justify-content: left;
      font-weight: normal;
      font-size: 19px;
      margin-top: 1em;
      margin-bottom: 16px;
      padding: 0 20px;
    }
    .note-produit {
     text-align: center;
    }
    .attr {
      float: none;
      position: static;
      margin-left: 0;
      padding: 0 20px 20px;
      select {
        right: auto;
      }
    }
    .desc {
      padding: 0 20px;
    }

    .produit_etiquette, .produit_etiquette2, .produit_etiquette3 {
      padding: 10px;
      max-width: 100%;
      margin-top: 8px;
      height: 48px;
      position: absolute;
      width: auto;
      line-height: normal;
      top: 0;
      h3, .h3 {
        box-shadow: none;
        background-color: transparent;
        text-align: left;
        padding: 0;
        display: inline-block;
      }
      &::after {
        display: none;
      }
    }

    &:hover, &:focus, &:active {
      .inside-addbasket, .inside-hors-stock {
        animation: productBounding .2s ease 0s 1 normal;
        animation-fill-mode: forwards;
        bottom: 0;
      }
      .addbasket {
        box-shadow: none;
      }
      .productImageWrap::before {
        opacity: .4;
      }
      .productImageWrap .zoom, .productImageWrap .mask .zoom {
        opacity: 1;
        .zoom-inner {
          animation: productBounding .2s ease .1s 1 normal;
          animation-fill-mode: forwards;
        }

      }
    }
  }

  &.accueil_boutique_template_1 .prix {
    .prixprod {
      font-size: 18px;
    }

    .oldprix {
      top: 0;
      font-size: 15px;
    }
  }

  &.accueil_boutique_template_2 .prix {
    .prixprod {
      font-size: 18px;
    }

    .oldprix {
      top: 0;
      font-size: 15px;
    }
  }
}

/* Templates produits */

.template_boutique {
  &.template_boutique_1 .produits {
    .nomprod a {
      text-align: center;
    }
    .prix {
      text-align: center;
    }
    .attr {
      padding: 0 20px 20px;
      width: 100%;
      text-align: center;
      select {
        width: 70%;
      }
    }
    .inside-addbasket span {
      font-size: 13px;
    }
  }

  &.template_boutique_2 .produits {
    .nomprod a {
      text-align: center;
      font-size: 25px;
      font-weight: bold;
    }
    .prix {
      text-align: center;
      font-size: 20px;
      .oldprix {
        color: red;
      }
    }
    .attr {
      padding: 0 20px 20px;
      width: 100%;
      text-align: center;
      select {
        width: 70%;
      }
    }
  }

  &.template_boutique_3 .produits {
    border-bottom: 1px solid rgba(0, 0, 0, .3);
    .nomprod a {
      font-size: 1.5rem;
    }
    .prixprod {
      font-size: 1.5rem;
    }
    .oldprix {
      font-size: 1.2rem;
      top: -2px;
    }
    .remise span {
      font-size: 1.2rem;
    }
    .productContentWrap {
      position: static;
      margin-bottom: 0;
    }
    .addbasket, .hors-stock {
      padding-top: 30%;
      width: 15%;
      bottom: auto;
      top: 4px;
      left: 10px;
    }
    .attr select {
      float: none;
    }
    &.is_etiquette .nomprod {
      margin-top: 1em;
    }
    .remise {
      top: 18px;
      right: 10px;
    }
    .produit_etiquette {
      height: auto;
      h3, .h3 {
        font-size: 18px;
      }
    }
    .addbasket, .hors-stock {
      padding-top: 30.33%;
      padding-top: calc(30% + 4px);
    }
    .note-produit {
      text-align: left;
      padding: 0 20px;
    }
  }

  &.template_boutique_4 .produits {
    .container-etiquette {
      margin-top: 0;
    }
    .produit_etiquette {
      height: auto;
      position: static;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      margin-top: 0;
      h3, .h3 {
        text-align: center;
        width: 100%;
        font-size: 17px;
      }
    }
    .productContentWrap {
      position: static;
    }
    .productImageWrap::before {
      height: auto;
      padding-top: 100%;
    }
    .addbasket .inside-addbasket span, .hors-stock .inside-hors-stock .sub-hors-stock {
      font-size: 12px;
    }
    .prix {
      margin-top: 20px;
      text-align: center;
    }
    .container-etiquette {
      position: absolute;
      top: 10px;
    }
  }

  &.template_boutique_5 .produits {
    .zoom {
      height: 100%;
      .zoom-inner {
        width: 100%;
      }
    }

    .block-btn-addbasket {
      margin-left: 0;
      margin-right: 0;
    }
    .addbasket {
      i.fa {
        width: auto;
      }
    }
    .addbasket, .hors-stock {
      position: static;
      padding: 0;
      width: 100%;
      .inside-addbasket, .inside-hors-stock {
        bottom: 0;
        position: static;
        transform: translateY(0);
      }
    }
    .attr {
      display: block;
      clear: both;
    }
    .productImageWrap {
      height: auto;
      min-height: 0;
      padding-bottom: 0;
      margin-bottom: 10px;
      &::before {
        height: auto;
        padding-top: 100%;
      }
    }
    .prix {
      margin-top: 0;
      span {
        display: block;
      }

      .oldprix {
        font-size: 80%;
        top: 0;
      }
    }
    .nomprod {
      margin-top: 0;
      display: block;
      font-size: 16px;
    }
    .produit_etiquette {
      height: auto;
      h3, .h3 {
        font-size: 12px;
      }
    }
    &:hover {
      .inside-addbasket, .inside-hors-stock {
        animation: none;
      }
    }
    .note-produit {
      text-align: left;
      padding: 0 20px;
    }
  }
}

/*********************************************************/
/********************* FICHE PRODUIT *********************/
/*********************************************************/

.template_fiche_produit {
  .message-wrap, #addcomment > div {
    box-shadow: 0 0 0 transparent;
  }
  #fiche-produit {
    .note-produit, .fa.fa-star, .fa.fa-star-o {
      color: #E6C376;
    }
    .brand {
      background: transparent !important;
      box-shadow: none !important;
    }
    .wrap-description {
      h1, .h1 {
        margin-top: 0;
      }
    }
    > div:nth-of-type(2n+1) {
      padding: 20px 9999px;
      margin: -20px -9999px 0;
      box-sizing: content-box;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, .3);
    }
    > div:nth-of-type(1) {
      box-shadow: 5px 0 5px 0 rgba(0, 0, 0, .3);
    }

    .top_fiche {
      font-size: 15px;
      .remise-produit {
        line-height: 40px;
        padding: 0 10px;
        border-radius: 0;
        height: 40px;
        left: auto;
      }
      .block-addbasket {
        .addbasket {
          display: inline-block;
        }
      }
    }
    .produit_etiquette {
      line-height: 40px;
      font-size: 0;
      h3, .h3 {
        padding: 0;
        margin: 0;
        line-height: normal;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 100%;
        box-shadow: none;
      }
    }
    .previews {
      position: relative;
    }
    .wrap-description {
      /*h1, > .row > .col.s8{
                display:none;
            }*/
      > .row {
        margin-top: 0;
      }

      .bloc-bt-quantity{
        .bt-quantity{
          height: auto;
        }
      }

      .declinaison-attribute-group, .product_options-group {
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        .notification_product {
          font-size: 1rem;
          margin-top: 10px;
        }
        input.quantite, input.boutique_product_attribute_input_free {
          text-align: right;
          border: 1px solid rgba(0, 0, 0, .3);
          margin: 0;
          padding: 5px 10px;
        }
        input.boutique_product_attribute_input_free {
          text-align: left;
        }
        .declinaison-attribute-group__item,.product_options-group__item {
          > label, > select, > input {
            display: inline-block;
            font-size: 1rem;
            line-height: normal;
            vertical-align: middle;
            float: none;
            font-weight: normal;
          }
        }
        label {
          &.radio_unique, &.radio_color {
            margin: 5px 5px;
            border-width: 2px;
            transition: all .2s linear;
            border-radius: 0;
            border-color: rgba(0, 0, 0, .3);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
            position: relative;
            top: 0;
            &:hover {
              border-color: rgba(0, 0, 0, .6);
              //margin-top: -5px;
              //margin-bottom: 15px;
              top: -5px;
              box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
            }
            &.radio-color-checked, &.radio-checked {
              border-color: rgba(0, 0, 0, .8);
            }
          }
          &.radio_color {
            width: 30px;
            height: 30px;
          }
          &.radio_unique {

          }
        }
      }
      .prix {
        text-align: left;
        border: 0;
      }
      .btn-details {
        font-size: 13px;
        text-transform: none;
      }
    }
  }
  &.template_fiche_produit_1 #fiche-produit {
    .top_fiche .ajouter-panier {
      //position:relative;
      padding-right: 30px;
    }
    .wrap-description {
      padding-right: 100px;
      box-sizing: border-box;
      position: relative;
      .sociaux {
        border: none;
        position: absolute;
        width: 100px;
        right: 0;
        top: 0;
        text-align: left;
      }
      .ajouter-panier {
        &::after, &::before {
          content: '';
          display: block;
          clear: both;
          margin: 20px 0;

        }
      }
      .prix {
        display: inline-block;
        float: left;
        margin: 0 15px 0 0;
        + .addbasket {
          float: left;
        }
      }
      .triangle-ficheprod-second {
        display: block !important;
        right: auto;
        left: -13.2%;
        top: 50px;
        z-index: 100;
        .remise-produit {
          width: auto;
          right: auto;
          left: 0;
        }
      }
    }
    .wrap-images {
      box-sizing: border-box;
      .remise-produit {
        display: none;
      }
    }
  }
  &.template_fiche_produit_2 #fiche-produit {
    .wrap-description h1,
    .wrap-description .h1 {
      margin-top: 0;
      text-align: center;
      width: 100%;
    }
    .sliderImageFicheProd {
      margin: 0;
    }
    .triangle-ficheprod .remise-produit {
      width: auto;
      font-size: 1.5rem;
    }
    .wrap-description .prix {
      .prixprod {
        font-size: 30px;
      }

      .oldprix {
        font-size: 20px;
        margin-left: 15px;
      }
    }

    .container-etiquette {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
  &.template_fiche_produit_3 #fiche-produit {
    .wrap-images .content-slider .bx-pager {
      top: auto;
    }
    .wrap-description {
      margin-top: 10px !important;
      .prix {
        width: 100%;
        float: none;
        .oldprix, .prixprod {
          display: inline-block;
          width: auto;
          margin-right: 5px;
        }
      }
      .details-descr-com {
        > .side-tabs, > .in-onglet {
          float: none;
          display: block;
          width: 100%;
        }
        > .side-tabs {
          .button {
            margin: 0 10px;
            padding: 5px 10px;
          }
        }
        > .in-onglet {
          padding-left: 0;
          padding-top: 15px;
          margin-top: 15px;
          border-top-width: 1px;
          border-top-style: solid;
        }
      }
      .side-tabs a::before {
        color: inherit;
      }
    }
  }
}

.produit_etiquette::after {
  display: none !important;
}

/* miniatures photos */
#fiche-produit .previews .previous, .previews .next {
  width: auto;
}

/* Permet de bien afficher le hover des images fiches produits pour le zoom */
.content_p .zoomPupImgWrapper > img, .content_p .zoomWrapperImage > img {
  max-width: none !important;
}

.triangle-ficheprod {
  position: absolute !important;
  right: 0;
  top: 0;
}

.template_fiche_produit_2 #fiche-produit .wrap-images .triangle-ficheprod {
  top: 0;
  right: 0;
}

/**************************************************/
/********************* SLIDER *********************/
/**************************************************/

.bx-wrapper .bx-viewport {
  left: 0;
}

.accueil_diaporama_template {
  clear: both;
  margin-bottom: 0;
  padding-top: 0;
  z-index: 0;
  .bx-wrapper {
    margin: 0 auto;
  }
  .slide .slide-text {
    max-width: 45%;
    width: auto;
    min-width: 72px;
    min-height: 52px;
    height: auto;
    top: 50%;
    margin-left: 50px;
    padding: 40px 20px;
    box-sizing: border-box;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    transform: translateY(-50%);
    position: absolute;
    &.nocomment.nolink {
      padding: 0;
      a.addcommentaires {
        right: 0;
      }
    }
    p, a {
      display: block;
      position: static;
      padding: 5px;
      margin: 0;
      &.addcommentaires {
        width: 72px;
        right: 20px;
        top: 0;
        text-align: center;
        padding: 15px 0;
        position: absolute;
        left: auto;
      }
    }
    p {
      font-family: 'Montserrat', 'Arial', sans-serif;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      background: transparent;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    a.slider-see-more {
      font-size: 20px;
      font-style: italic;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 10px;
      position: relative;
      text-align: center;
      right: auto;
      display: block;
      width: 100%;
    }
  }
  .bx-controls, .slide-text {
    opacity: 0;
    transition: opacity .2s linear;
  }
  .addcommentaires {
    top: 0;
    left: 0;
    right: auto;
    background: transparent !important;
  }
  .bx-controls-direction > a {
    background-image: none;
    height: 60px;
    margin-top: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    line-height: 60px;
    text-align: center;
    text-indent: -9999px;
    transition: all .2s linear;
    transform: translateY(-50%);
    &::after {
      display: block;
      width: 100%;
      font-size: 22px;
      font-family: 'fontAwesome', sans-serif;
      position: absolute;
      top: 0;
      left: 0;
      text-indent: 0;
    }
    &.bx-prev::after {
      content: "\f104";
    }
    &.bx-next::after {
      content: "\f105";
    }
    &:hover {
      margin-top: -5px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    }
  }
  .bx-wrapper .bx-pager {
    bottom: 20px;
    padding-top: 0;
    .bx-pager-link {
      width: 15px;
      height: 15px;
      border-width: 2px;
      border-style: solid;
      background: transparent;
      margin: 0 5px;
      border-radius: 0;
    }
  }
  &:hover {
    .bx-controls {
      opacity: 1;
    }
  }

  .slide > li, .slide > div {
    &:hover {
      .slide-text {
        opacity: 1;
      }
    }
  }

  &.accueil_diaporama_template_2 {
    .slide > div > p {
      box-shadow: 0 0 5px rgba(0, 0, 0, .5) inset;
      padding: 0 5px;
      box-sizing: content-box;
      margin-left: -5px;
    }
  }
  &.accueil_diaporama_template_1, &.accueil_diaporama_template_3 {
    padding-bottom: 30px;
    .slide {
      .center-img-slider {
        margin-bottom: 0;
      }
    }
    .bx-controls {
      opacity: 1;
    }
    .bx-wrapper .bx-pager {
      bottom: auto;
      top: 100%;
    }
  }
  &.accueil_diaporama_template_4 {
    &, .slide li {
      margin: 0;
    }
  }
  &.accueil_diaporama_template_5 {
    .slide .slide-text {
      max-width: calc(100% - 100px);
      width: calc(100% - 100px);
      margin: 0 auto;
      left: 0;
      right: 0;

      p {
        font-size: 15px;
      }
      a.slider-see-more {
        margin-bottom: 0;
      }
      &.nocomment.nolink {
        width: 72px;
      }
      &.nocomment.nolink.comm-interdit {
        width: 0;
      }
      &.nocomment.comm-interdit {
        .slider-see-more {
          top: 0;
        }
      }
    }
  }
  &.accueil_diaporama_template_6 {
    .slide li {
      margin: 0;
    }
  }
}

.produits-accueil.template_boutique_accueil.accueil_boutique_template_1 .bx-wrapper,
.banner_diaporama .bx-wrapper,
#fiche-produit,
.content-slider > .bx-wrapper {
  .bx-controls-direction > a, .previews .previous, .previews .next {
    background-image: none;
    height: 60px;
    width: 32px;
    margin-top: 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    line-height: 60px;
    text-align: center;
    text-indent: -9999px;
    transition: all .2s linear;
    top: 50%;
    transform: translateY(-50%);
    > i {
      display: none;
    }
    &::after {
      display: block;
      width: 100%;
      font-size: 22px;
      font-family: 'fontAwesome', sans-serif;
      position: absolute;
      top: 0;
      left: 0;
      text-indent: 0;
    }
    &.bx-prev::after, &.previous::after {
      content: "\f104";
    }
    &.bx-next::after, &.next::after {
      content: "\f105";
    }
    &:hover {
      margin-top: -5px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    }
  }
  .bx-pager {
    bottom: auto;
    top: 100%;
    padding-top: 0;
    .bx-pager-link {
      width: 15px;
      height: 15px;
      border-width: 2px;
      border-style: solid;
      background: transparent;
      margin: 0 5px;
      border-radius: 0;
    }
  }
}

.myGallery .slider-see-more,
.pgwSlider .slider-see-more {
  position: absolute !important;
  top: 20px !important;
}

.pgwSlider ul.ps-list li {
  .button.slider-see-more {
    display: none !important;
  }
}

.slider-iview .slider-see-more {
  position: absolute !important;
  top: auto !important;
}

.reservation__list > * {
  flex: 1 1 auto;
}

#fiche-produit .top_fiche .button {
  display: inline-block;
}

/************************************************************/
/********************* GALERIE D'IMAGES *********************/
/************************************************************/

.button_galerie {
  margin: 0;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 11;
  height: 55px;
  width: 55px;
  line-height: 55px;
  text-align: center;
  font-size: 25px !important;
  font-weight: bold;
  font-family: 'Montserrat', 'Arial', sans-serif;
  transition: all .2s linear;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

.template_album.album-photo .list-photos .galerie {
  overflow: hidden;
  margin-bottom: 15px;
  .bloc-cache-overflow {
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
    padding: 45px 0 0 0;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
  }
  //.commentP, .bloc-titre-galerie, .center-img .mask{
  .commentP {
    position: absolute;
    top: 5px;
    left: 65px;
    height: 90%;
    width: 90%;
    height: calc(100% - 10px);
    width: calc(100% - 70px);
    max-width: 0;
    max-height: 0;
    overflow: hidden;
    transition: all .2s linear;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    font-size: 0;
    padding: 0;
    display: block;
    opacity: 1;
    transform-origin: top left;
    transform: none;
    z-index: 10;
    &::before {
      content: '';
      display: inline-block;
      height: 100%;
      width: 0;
      vertical-align: middle;
    }
    > p, .bloc-titre-galerie {
      vertical-align: middle;
      width: 100%;
      margin: 0;
      display: inline-block;
      opacity: 1;
      font-size: 14px;
      float: none;
      text-align: center;
      max-width: 100%;
      height: auto;
    }
  }

  .center-img {
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2) inset;
      z-index: 1;
    }
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 1);
      opacity: 0;
      z-index: 2;
      transition: all .2s linear;
    }
  }

  &.sans-commentaire {
    //.commentP, .bloc-titre-galerie, .center-img .mask{
    .bloc-titre-galerie, .commentP {
      padding-top: 0;
    }
  }

  .addcommentaires {
    top: 65px;
    right: auto;
    left: 5px;
    height: 55px;
    width: 55px;
    line-height: 55px;
    text-align: center;
    transition: all .2s linear;
    display: block;
    opacity: 1;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    position: absolute;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    z-index: 11;
  }

  &.sans-texte {
    .addcommentaires {
      top: 5px;
    }
  }

  &.hover {
    //.commentP, .bloc-titre-galerie, .center-img .mask{
    .bloc-titre-galerie, .commentP {
      max-width: 90%;
      max-height: 90%;
      max-height: calc(100% - 10px);
      max-width: calc(100% - 70px);
    }
    .commentP {
      padding: 30px;
    }
    .center-img::after {
      opacity: .6;
    }
  }
  &.sans-texte.sans-commentaire.hover {
    //.commentP, .bloc-titre-galerie, .center-img .mask{
    .commentP {
      max-width: 0;
      max-height: 0;
    }
    .center-img::after {
      opacity: .6;
    }
  }
  &:hover {
    &.sans-texte {
      .addcommentaires {
        top: 5px;
      }
    }
    .addcommentaires {
      top: 65px;
    }
    .center-img::after {
      opacity: .6;
    }
  }
  .mask {
    display: none;
  }
}

.template_album.album-photo.template_album_2 .list-photos .galerie {
  margin-bottom: 0;
}

.template_album_3.album-photo .list-photos .galerie {
  margin: 0;
}

.template_album.album-photo.template_album_4 .list-photos .galerie {
  position: relative;
}

/******************************************************/
/********************* LIVRE D'OR *********************/
/******************************************************/

.template_livreor_1 .message_lo {
  padding: 20px;
  border: 1px solid;
}

/************************************************/
/********************* BLOG *********************/
/************************************************/

.apercu-article h2.titre-article,
.apercu-article .h2.titre-article {
  min-width: 250px !important;
  width: auto !important;
  max-width: 90% !important;
}

.row.two-column-blog, .row.one-column-blog {
  padding: 0 !important;
}

/**************************************************/
/********************* FOOTER *********************/
/**************************************************/

footer {
  text-align: center;
  clear: both;
  width: 100%;
  margin-top: 0;
  overflow: hidden;
  padding: 20px 20px 20px 25px;
  box-shadow: 5px 0 5px 0 rgba(0, 0, 0, .05) inset;
  #pub ~ .mention {
    padding-top: 20px;
  }
}

/*************************************************/
/********************* AUTRE *********************/
/*************************************************/

#block-flottant-search .btn-search {
  border-radius: 0;
  height: 35px;
  width: 35px;
  text-align: center;
  padding: 0;
  line-height: 0;
}

h3.faq_question,
.h3.faq_question {
  display: block;
  padding: 10px 0;
  box-shadow: none;
}

/* Template Panier */
body.cmonsite-panier-2 {
  .menu {
    width: 80%;
    float: right;
  }
}

body.cmonsite-panier-3 {
  .menu {
    max-width: 80%;
    float: right;
  }
  #main-conteneur header {
    z-index: auto;
    &.is-fixed {
      z-index: 100;
    }
  }
  #blocContent {
    .template-panier.template-panier-3 {
      position: absolute;
      top: 100%;
      margin-top: 0;
      border: none !important;
      box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.5);
      z-index: 2;
    }
  }
}

body.cmonsite-panier-2 .template-panier.template-panier-2 {
  border: none !important;
  flex: 0 0 auto;
  max-height: 100px;
  #panier #header-panier {
    line-height: 81px;
    font-family: 'Montserrat', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    .fa-shopping-cart {
      vertical-align: baseline;
    }
    .count-live {
      top: 50px;
    }
    .paniertoggle {
      position: absolute;
      padding: 0;
      top: 50%;
      height: 40px;
      right: 0;
      margin-top: -20px;
      width: 40px;
      text-align: center;
      .fa {
        float: none;
        line-height: 40px;
        vertical-align: top;
      }
    }
  }
  #paniercommande .ajout_panier a {
    line-height: 24px;
  }
}

/*-----------------------------
    FLUX PANIER
-------------------------------*/

#flux-panier.template_flux_panier.template_flux_panier_1 {
  h4 {
    margin: 0;
  }

  div#information .list_address {
    margin: 0 0 20px;
  }
}

.template_flux_panier_2 .side-tabs.etapes {
  margin-top: 30px;
}

/*********************************************************/
/********************* PAGE ACCOUNT **********************/
/*********************************************************/

.page-account, .page-order, .page-profile,.page-subscription {
  .sidebar {
    float: none;
    width: 100%;
  }
  a.button:last-child, button:last-child {
    margin-bottom: 5px !important;
  }
}

/*********************************************************/
/********************* MEDIA QUERIES *********************/
/*********************************************************/

@media #{$xlarge-and-down} {
  .wrapper, .header-conteneur, #pub {
    width: $large-screen - 80;
  }


  /* Taille du titre de chaque page */
  $largeur_fenetre_grand: $large-screen - 80;
  #bloc-fil-title h2, #bloc-fil-title .arianne,
  #bloc-fil-title h1,
  #bloc-fil-title .h2,
  #bloc-fil-title .h1 {
    max-width: $largeur_fenetre_grand;
  }

  /* Produits */
  .template_boutique_all {
    .produits {
      .addbasket .inside-addbasket span,
      .hors-stock .inside-hors-stock .sub-hors-stock {
        font-size: 14px;
      }
    }
    &.template_boutique_4 .produits {
      .addbasket .inside-addbasket span, .hors-stock .inside-hors-stock .sub-hors-stock {
        font-size: 13px;
      }
    }
  }

  /* Fiche produits */
  .template_fiche_produit.template_fiche_produit_1 #fiche-produit .wrap-description .triangle-ficheprod {
    left: -15%;
  }
}

@media #{$large-and-down} {
  .wrapper, .header-conteneur, #pub {
    width: $medium-screen - 80;
  }

  /* Taille du titre de chaque page */
  $largeur_fenetre_grand: $medium-screen - 80;

  #bloc-fil-title h2, #bloc-fil-title .arianne,
  #bloc-fil-title h1,
  #bloc-fil-title .h2,
  #bloc-fil-title .h1 {
    max-width: $largeur_fenetre_grand;
  }

  /* Responsive des produits */
  .template_boutique_all {
    .produits {
      .addbasket .inside-addbasket span {
        font-size: 0;
        line-height: 0;
        i {
          font-size: 22px;
        }
      }
      .hors-stock .inside-hors-stock .sub-hors-stock {
        font-size: 13px;
      }
    }
  }

  #wrapper .produits-accueil.template_boutique_accueil .list-produits .produits .produit_etiquette {
    height: auto;
    h3, .h3 {
      font-size: 17px;
    }
  }

  /* Fin Responsive des produits */

  /* Fiche produits */
  .template_fiche_produit.template_fiche_produit_1 #fiche-produit .wrap-description .triangle-ficheprod {
    left: -16.7%;
  }
}

@media #{$medium-and-down} {
  body.cmonsite-panier-2, body.cmonsite-panier-3 {
    .menu {
      z-index: 3;
      max-width: 100%;
      float: none;
    }
  }
  body.cmonsite-panier-2 {
    .menu {
      width: 100%;
    }
  }
  .wrapper, .header-conteneur, #pub {
    width: $small-screen;
  }

  /* Taille du titre de chaque page */
  $largeur_fenetre_grand: $small-screen;

  #bloc-fil-title h2, #bloc-fil-title .arianne,
  #bloc-fil-title h1,
  #bloc-fil-title .h2,
  #bloc-fil-title .h1 {
    max-width: $largeur_fenetre_grand;
  }

  /*Suppression du bloc de recherche à droite*/
  #block-flottant-search {
    display: none;
  }
  /* Met une position par défaut du logo et du slogan */
  #headercontent .slogan {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    margin-top: 15px;
    text-align: center !important;
    font-size: 1.2rem !important;
    display: block;
  }
  #headercontent #logo {
    height: auto !important;
    position: relative !important;
    width: 100% !important;
    text-align: center !important;
    left: 0 !important;
    top: 0 !important;
    margin: 0;
    display: block;
    img {
      margin: 0 !important;

    }
  }
  /* Passage des deux colonnes en une */
  .sidebar, section.col-md-10 {
    float: none;
    padding: 0;
  }
  .sidebar {
    display: none;
  }
  section.col-md-10 {
    margin-top: 20px;
    width: 100%;
  }

  /* Responsive des produits */

  .template_boutique_all {
    .produits {
      .productImageWrap {
        position: relative;
        .zoom, .mask .zoom {
          opacity: 1;
          display: block;
          .zoom-inner {
            bottom: 0;
            transform: translateY(0);

          }
        }
      }
      .addbasket, .hors-stock {
        opacity: 1;
        //margin-top:30px;
        .inside-addbasket, .inside-hors-stock {
          bottom: 0;
          transform: translateY(0);
          .sub-hors-stock {
            font-size: 17px;
          }
        }
      }
      .nomprod {
        font-size: 22px;
        display: block;
        text-align: center;
      }
      .prix {
        text-align: center;
        font-size: 18px;
      }
      .attr {
        margin: 0 auto;
        width: 50%;
        select {
          width: 100%;
        }
      }
      .remise {
        right: auto;
        left: 0;
      }
      &:hover, &:focus, &:active {
        .inside-addbasket, .inside-hors-stock, .productImageWrap .zoom .zoom-inner, .productImageWrap .mask .zoom .zoom-inner {
          animation-name: none;
        }
      }
    }
    &.template_boutique_3 .produits {
      .produit_etiquette {
        h3, .h3 {
          font-size: 13px;
        }
      }
      .remise {
        right: 10px;
        left: auto;
      }
      .nomprod, .prix {
        text-align: left;
      }
      .attr {
        margin: 0;
      }
    }
    &.template_boutique_5 .produits {
      .remise {
        left: 10px;
      }
    }
  }

  body .produits-accueil.template_boutique_accueil .list-produits .produits .produit_etiquette {
    height: 48px;
    h3, .h3 {
      font-size: 22px;
    }
  }

  /* Fin Responsive des produits */

  /* Slider Responsive */

  .accueil_diaporama_template {
    .slide > div, .slide > li {
      // Bouton chow / hide les texte dans le slider
      .button_slider {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        font-size: 25px;
        font-weight: bold;
        font-family: 'Montserrat', 'Arial', sans-serif;
        transition: all .2s linear;
        margin-top: 0;
        line-height: 55px;
        height: 55px;
        width: 55px;
        box-sizing: border-box;
        text-align: center;
        z-index: 2;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
        &:hover {
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        }
      }
      .slide-text {
        opacity: 1;
        width: 90%;
        height: 90%;
        max-width: 0;
        max-height: 0;
        min-width: 0;
        min-height: 0;
        transform: none;
        margin: 0;
        top: 5%;
        left: 5%;
        display: block;
        vertical-align: middle;
        padding: 0 20px;
        line-height: 0;
        overflow: hidden;
        transition: all .2s linear;
        font-size: 0;
        &::before {
          display: inline-block;
          height: 100%;
          vertical-align: middle;
          content: '';
          width: 0;
        }
        .content-slide-text {
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          /* IE */
          line-height: inherit;
          /* Other */
          line-height: initial;
          width: 100%;
        }
        p, .slider-see-more {
          display: inline-block;
          width: 100%;
          /* IE */
          line-height: inherit;
          /* Other */
          line-height: initial;
        }
        a.addcommentaires {
          width: 51px;
          right: 0;
          font-size: 16px;
        }
      }
      .button_slider.slide-ouvert {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        ~ .slide-text {
          max-width: 90%;
          max-height: 450px;
        }
      }
    }
    .bx-controls {
      display: block;
      opacity: 1;
    }
    /*&.hover{
            .bx-controls {
                display:none;
            }
        }*/
    &.accueil_diaporama_template_5 .slide > div .button_slider.slide-ouvert ~ .slide-text {
      max-width: 90%;
      width: 90%;
      opacity: 1;
    }
    &.accueil_boutique_template_5 .bx-wrapper {
      max-width: 100%;
    }
  }

  /* Fin Slider Responsive */

  /* Fiche produits */
  .template_fiche_produit.template_fiche_produit_1 #fiche-produit .wrap-description .triangle-ficheprod {
    left: -21.7%;
  }
}

@media #{$medium-and-down} {
  a.menu_burger {
    cursor: pointer;
  }

  /* Template responsive */
  body.cmonsite-panier-2 #nav-principal {
    > a.menu_burger {
      padding-right: 0;
    }
    > ul {
      + .template-panier.template-panier-2 {
        top: 0;
        width: auto;
        position: relative;
        right: auto;
        #header-panier {
          right: 85px;
          top: 0;
          padding: 0;
          line-height: 50px;
          .count-live {
            top: 25px;
          }
          .paniertoggle {
            width: 50px;
            height: 50px;
            top: 0;
            margin: 0;
          }
        }
        #panier #paniercontent {
          padding-top: 30px;
        }
      }
    }
  }

  body.cmonsite-panier-3 #blocContent {
    .template-panier.template-panier-3 {
      .count-live {
        left: 40px;
        top: 20px;
      }
    }
  }

  body header nav#nav-principal {
    > ul {
      top: 70px;
      left: 0;
    }

    > a.menu_burger {
      top: 0;
      right: 0;
      margin-top: 0;
      margin-right: 20px;
    }
  }

  header {
    z-index: 2;
    position: relative;
    top: 0;
    .header-conteneur #blocContent {
      height: auto;
      position: relative;
    }
    #headercontent {
      width: 100%;
      float: none;
      height: auto;
      position: relative;
      min-height: 90px;
      padding-top: 60px;
      .container-slogan {
        /* IE */
        line-height: inherit;
        /* Other */
        line-height: initial;
        width: 100%;
        text-align: center;
        h1, .h1 {
          position: relative;
          left: 0;
          top: 0;
          width: 100%;
          padding: 10px 0;
        }
      }
    }
    .menu {
      float: none;
      position: absolute;
      width: 100%;
      top: 0;
      z-index: 10;
    }
  }
  .wrapper-content {
    position: relative;
    z-index: 1;
  }

  /* Menu responsive */
  nav#nav-principal {
    width: 100%;
    padding: 0;
    float: none;
    clear: both;
    position: relative;
    a, a span {
      white-space: normal;
    }
    a {
      padding: 0 10px;
    }
    > a.menu_burger {
      position: relative;
      top: 100%;
      right: 25px;
      padding: 0;
      text-align: center;
      width: 50px;
      height: 50px;
      line-height: 0 !important;
      font-size: 59px;
      z-index: 50;
      margin-top: -25px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
      &::before, &::after {
        font-weight: bold;
        content: '';
        display: block;
        position: absolute;
        width: 28px;
        height: 4px;
        top: 50%;
        left: 11px;
        font-size: 30px;
        margin: 0;
        transition: all .2s linear;
        transform: rotate(0deg);
      }
      &::before {
        margin-top: -8px;
      }
      &::after {
        margin-top: 8px;
      }
    }
    &.nav_ouverte > a.menu_burger {
      font-size: 0;
      &::before {
        margin-top: 0;
        transform: rotate(45deg);
      }
      &::after {
        margin-top: 0;
        transform: rotate(-45deg);
      }
    }
    > ul > li.hasDropdown > a span:after {
      content: none;
    }
    ul {
      width: 100%;
      display: none;
      position: absolute;
      top: auto;
      height: auto;
      min-width: 0;
      text-align: center;
      li:hover ul {
        left: 0;
      }
      li ul {
        left: 0;
        opacity: 1;
        top: 100%;
        margin-top: 0;
        padding: 0;
        margin-left: 0;
        min-width: 0;
        &::before {
          left: 50%;
          top: -10px;
          margin-left: -6px;
          border-width: 0 6px 8px 6px;
          border-left-color: transparent;
          border-right-color: transparent;
          border-top-color: transparent;
        }
        ul {
          box-sizing: content-box;
          left: -2px;
          top: 100%;
        }
      }
    }
    > ul li ul li:hover > ul {
      left: -2px;
      top: 100%;
      margin-left: 0;
      &::before {
        left: 50%;
        top: -10px;
        margin-left: -6px;
        border-width: 0 6px 8px 6px;
      }
    }
    li {
      width: 100%;
      display: block;
      margin: 0;
      padding: 0;
    }
    a {
      /*Font size + padding : il faut au moins que le bloc fasse 40px de haut, taille minimale pour un bouton au doigt*/
      font-size: 16px;
      line-height: 55px;
      padding: 0;
      width: 100%;
      text-align: center;
      > span::after {
        display: none !important;
      }
    }
    &.nav_ouverte {
      > ul {
        display: block;
      }
    }

  }
  .menu_burger {
    text-align: center;
    &::before {
      content: '\f0c9';
      display: inline-block;
      margin-right: 5px;
      font-family: 'FontAwesome', sans-serif;
    }
  }
  .dropDown {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: bold;
    display: block;
    height: 55px;
    width: 60px;
    line-height: 55px;
    cursor: pointer;
    text-align: center;
    font-size: 1.2rem;
    &::before {
      font-family: 'fontAwesome', sans-serif;
      content: "\f107";
    }
  }
  .sousmenu_ouvert > .dropDown::before {
    content: "\f106";
  }
}

@media #{$medium-and-up}{
  .menu_burger {
    display: none;
  }
}

@media #{$small-and-down} {
  .wrapper, .header-conteneur, #pub {
    width: 100%;
  }
  header .menu, #headercontent {
    float: none;
  }
  #minisite-sidebar {
    display: none;
  }
  /* Taille du titre de chaque page */
  #bloc-fil-title, section.col-md-10 #bloc-fil-title {
    margin-left: -20px;
  }

  /* Tableau responsive */
  @import '../../design/helper/responsive/mobile/mobile';

  //Panier

  body.cmonsite-panier-3 {
    #headercontent {
      padding-bottom: 60px;
      min-height: 150px;
    }
    #blocContent .template-panier.template-panier-3 {
      position: absolute;
      top: auto;
      bottom: 10px;
      margin: auto;
      left: 0;
      right: 0;
    }
  }

  /* Responsive des produits */

  .produits-accueil.template_boutique_accueil .list-produits,
  #wrapper .template_boutique,
  #wrapper #fiche-produit .template_boutique {
    .produits {
      padding: 30px 0;
      border-bottom: 1px solid rgba(0, 0, 0, .3);
      margin: 0;
      .addbasket, .hors-stock {
        margin-top: 30px;
      }
      .remise {
        top: 94px;
      }
    }
    &.template_boutique_1 .produits {
      .addbasket .inside-addbasket span {
        font-size: 17px;
        line-height: 1.2;
      }
    }
    &.template_boutique_3 .produits {
      .addbasket, .hors-stock {
        padding-top: 100%;
        width: 50%;
        bottom: auto;
        top: 0;
        left: 0;
        .inside-addbasket, .inside-hors-stock {
          span, .sub-hors-stock {
            font-size: 17px;
          }
        }
      }
      .produit_etiquette {
        h3, .h3 {
          font-size: 22px;
        }
      }
      .remise {
        right: auto;
        left: 0;
      }
      .nomprod, .prix {
        text-align: center;
      }
      .attr {
        margin: 0 auto;
      }
    }
    &.template_boutique_4 .produits {
      .container-etiquette {
        top: 42px;
      }
    }
    &.template_boutique_5 .produits {
      .produit_etiquette {
        h3, .h3 {
          font-size: 22px;
        }
      }
      .remise {
        left: 0;
      }
    }
  }

  //Produits accueil

  body .produits-accueil.template_boutique_accueil.accueil_boutique_template_1,
  body .produits-accueil.template_boutique_accueil.accueil_boutique_template_2 {
    .produits {
      .mask {
        display: block;
      }
    }
  }

  /* Fin Responsive des produits */

  /* Responsive des slider page d'accueil */

  .accueil_diaporama_template {
    .slide > div, .slide > li {
      .slide-text {
        p {
          font-size: 25px;
        }
      }
    }
  }

  /* Fin Responsive des slider page d'accueil */

  /* Fiche produits */
  .template_fiche_produit.template_fiche_produit_1 #fiche-produit {
    .wrap-description {
      margin-top: 15px;
      .triangle-ficheprod {
        left: auto;
        right: 0;
        top: 0;
      }
      .sociaux {
        top: 50px;
      }
    }
  }
}

@media #{$large-and-up} {
  .sous_navigation_button_p.hasDropdown > a::after {
    content: "\f105";
    display: block;
    position: absolute;
    right: 10px;
    font-family: 'fontAwesome', sans-serif;
    top: 12px;
  }
}

